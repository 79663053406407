/* eslint-disable no-console */
import { upsertSubscription } from '@app/http/notification';

// this registration is checked on the dashboard.vue because this also manipulates the DOM.
export const registerServiceWorkerNotifications = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js', {
        scope: '/'
      });

      if (Notification.permission === 'granted') {
        document.querySelector('#allowNotifications').style.display = 'none';
        getSubscription(registration);
      } else if (Notification.permission === 'blocked' || Notification.permission === 'denied') {
        document.querySelector('#allowNotifications').style.display = 'block';
        document
          .querySelector('#allowNotificationsLink')
          .addEventListener('click', () => showEnableNotificationWithPhoneSettings());
      } else {
        document.querySelector('#allowNotifications').style.display = 'block';
        document
          .querySelector('#allowNotificationsLink')
          .addEventListener('click', () => requestNotificationAccess(registration));
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

function showEnableNotificationWithPhoneSettings() {
  document.querySelector('#showEnableNotificationWithPhoneSettings').style.display = 'block';
}

function requestNotificationAccess(registration) {
  Notification.requestPermission(function(status) {
    if (status == 'granted') {
      document.querySelector('#allowNotifications').style.display = 'none';
      getSubscription(registration);
    } else if (status == 'denied') {
      window.location.reload();
    } else {
      console.error(`Registration failed with ${status}`);
    }
  });
}

function getSubscription(registration) {
  registration.pushManager.getSubscription().then(function(sub) {
    if (sub === null) {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: window.localStorage.getItem('publicKeyNotifications')
        })
        .then(function(sub) {
          upsertSubscription(sub).then((response) => {
            if (!response.ok) {
              console.error('upsert failed - unsubscribing.');
              sub.unsubscribe();
            } else {
              logSubscription(sub);
            }
          });
        })
        .catch(function(e) {
          console.error('Unable to subscribe to push', e);
        });
    } else {
      logSubscription(sub);
    }
  });
}

const logSubscription = (sub) => {
  console.log(sub);
};
