export const english = {
  'chatbot.form.email': 'Email',
  'chatbot.form.firstname': 'First name',
  'chatbot.form.lastname': 'Last name',
  'chatbot.form.password': 'Password',
  'chatbot.form.role': 'Role',
  'chatbot.form.submit': 'Send',
  'chatbot.form.update': 'Update',
  'chatbot.form.save': 'Save',
  'chatbot.form.username': 'Username',
  'chatbot.nav.logout': 'Sign out',
  'chatbot.nav.back': 'Back',
  'chatbot.questions.title': 'Chatbot questions',
  'chatbot.settings.avatar': 'Avatar',
  'chatbot.settings.botbackgroundcolor': 'Chatbot background color',
  'chatbot.settings.bottextcolor': 'Chatbot text color',
  'chatbot.settings.chatbotname': 'Chatbot name',
  'chatbot.settings.design': 'Design',
  'chatbot.settings.forwardingemail': 'Forwarding email',
  'chatbot.settings.forwardingemaildescription':
    "If the chatbot can't find the right question to answer, it can ask the onboardee to forward the question. \nYou can adjust which email addresses should be used to forward the question in the list below.",
  'chatbot.settings.introduction': 'Introduction',
  'chatbot.settings.position': 'Position',
  'chatbot.settings.settings': 'Chatbot information',
  'chatbot.settings.responsesettings': 'Response settings',
  'chatbot.settings.themecolor': 'Theme color',
  'chatbot.settings.title': 'Chatbot settings',
  'chatbot.settings.titlebarcolor': 'Title bar color',
  'chatbot.settings.uploadavatar': 'Upload a new avatar',
  'chatbot.settings.uploadwidgeticon': 'Upload new widget icon',
  'chatbot.settings.userbackgroundcolor': 'User background color',
  'chatbot.settings.usertextcolor': 'User text color',
  'chatbot.settings.widgeticon': 'Widget icon',
  'chatbot.settings.unrecognizedintentresponse': 'Other response',
  'chatbot.settings.anotheremail': 'Add another email address',
  'chatbot.settings.addemail': 'Add email address',
  'chatbot.settings.otherresponse': "Add another response in case the user doesn't match any filter",
  'chatbot.settings.unrecognizedintentresponsedescription':
    "If email forwarding isn't set up, the chatbot will respond with the text below. This also applies to specific filters, which may not be set up in the list above.",
  'chatbot.title': 'Chatbot',
  'chatbot.training.nofirstconfiguration': 'Before you can start the training, the chatbot settings must be set up.',
  'chatbot.training.searchplaceholder': 'Search for a question intent or type a keyword',
  'chatbot.training.create': 'New intent',
  'chatbot.training.createsubtitle': 'Make your chatbot smarter',
  'chatbot.training.improve': 'Improve your chatbot',
  'chatbot.training.incompletequestions': 'Incomplete questions',
  'chatbot.training.incompletequestionssubtitle': 'You have {number} incomplete questions',
  'chatbot.training.missingtranslations': 'Missing translations',
  'chatbot.training.missingtranslationssubtitle': 'You have {number} questions missing translations',
  'chatbot.training.inactivequestions': 'Inactive questions',
  'chatbot.training.inactivequestionssubtitle': 'You have {number} inactive questions',
  'chatbot.training.pendingquestions': 'Pending questions',
  'chatbot.training.pendingquestionssubtitle': 'You have {number} pending questions',
  'chatbot.training.publishquestions': 'Publish questions',
  'chatbot.training.publishquestionssubtitle': 'You have {number} questions to be pushed',
  'chatbot.training.publishablequestions': 'Publishable questions',
  'chatbot.training.title': 'Chatbot training',
  'chatbot.training.training': 'Training',
  'chatbot.training.trainingsubtitle': 'You have {number} flagged questions',
  'chatbot.training.adddefaultanswer': 'Please add a default answer',
  'chatbot.training.nocategory': 'No category',
  'chatbot.users.adduser': 'Add user',
  'task.title.tasks': 'Tasks',
  'task.createtask.deadline.lastworkday': 'last workday.',
  'task.createtask.leavingonorafter': 'Create only for offboardees leaving on or after: ',
  'task.createtask.offboardee.selectonboardee': 'Select offboardee',
  'task.createtask.offboardee.tooltip': 'Select one offboardee',
  'task.createtask.offboardee': 'Offboardee',
  'task.createtask.taskassignee.offboardee': 'Offboardee',
  'task.title.task': 'Task',
  'task.title.taskoverview': 'Task Overview',
  'task.title.missingtranslations': 'Missing Translations',
  'task.title.missingtranslationshide': 'Hide Missing Translations',
  'task.title.missingtranslationsshow': 'Show Missing Translations',
  'task.title.filters': 'Filters',
  'task.title.tasksassignee': 'Your Tasks',
  'task.title.canceledtasks': 'Canceled tasks',
  'task.link.createtasktitle': 'Create task',
  'task.link.createtasksubtitle': 'Add a new task to your task library',
  'task.link.missingtranslationstitle': 'Missing translations',
  'task.confirm.deletetasktitle': 'Are you sure you want to delete this task?',
  'task.confirm.canceltasktitle': 'Are you sure you want to cancel this task?',
  'task.confirm.reopentasktitle': 'Are you sure you want to reopen this task?',
  'task.confirm.deletetaskdescription':
    'You are about to delete this task, this task will now be deleted for existing onboarding processes and will become unavailable for future onboarding processes. This action is not reversable!',
  'task.confirm.missingtranslationstitle': 'You have missing translations',
  'task.confirm.missingtranslationsdescription':
    'Psst... You are about to save this task with some missing translations. Do not worry, you can add them later. We will use your default language until the tasks have been translated. You are missing translations for: {missingLanguages}',
  'task.confirm.missingtranslations':
    'You are about to save this task without any translations. Do not worry, you can add them later. We will use your default language until the tasks have been translated.',
  'task.confirm.changedtaskassigneetitle': 'Are you sure you want to change the task assignee type?',
  'task.confirm.chanedtaskassigneedescription':
    'You are about to change the assignee type, all previously assigned tasks will be re-assigned to the new assignee and the previous task assignee will be overwritten and deleted. This action is not reversable!',
  'task.button.addtranslations': 'Add translations',
  'task.button.savetask': 'Save task',
  'task.button.cancel': 'Cancel',
  'task.button.revertchange': 'Undo change',
  'task.button.savetaskassignee': 'Confirm change',
  'task.button.confirmcontinue': 'Yes, continue',
  'task.button.cancelno': 'No, cancel',
  'task.action.deletetask': 'Delete task',
  'task.action.keeptask': 'Keep task',
  'task.action.canceltask': 'Cancel task',
  'task.action.reopentask': 'Reopen task',
  'task.search.placeholdersearch': 'Search for a task',
  'task.createtask.tasktitle': 'Task title',
  'task.createtask.deadline': 'Deadline',
  'task.createtask.deadline.singularday': 'day',
  'task.createtask.deadline.pluraldays': 'days',
  'task.createtask.deadline.before': 'before',
  'task.createtask.deadline.after': 'after',
  'task.createtask.onboardee.tooltip': 'Select one onboardee',
  'task.createtask.onboardee': 'Onboardee',
  'task.createtask.onboardee.selectonboardee': 'Select onboardee',
  'task.createtask.title.specific': 'Add specific task',
  'task.createtask.title': 'Add new task',
  'task.createtask.deadline.firstworkday': 'first workday.',
  'task.createtask.startingonorafter': 'Create only for onboardees starting on or after',
  'task.createtask.taskassignee': 'Assignee',
  'task.createtask.taskassignee.tooltip': 'Select one task assignee',
  'task.createtask.taskassignee.user': 'User',
  'task.createtask.taskassignee.keycontact': 'Key contact',
  'task.createtask.taskassignee.onboardee': 'Onboardee',
  'task.createtask.taskassignee.selectkeycontact': 'Select key contact',
  'task.createtask.taskassignee.selectuser': 'Select user',
  'task.createtask.taskassignee.manager': 'Manager',
  'task.createtask.taskassignee.hr': 'HR',
  'task.createtask.taskassignee.buddy': 'Buddy',
  'task.createtask.filters': 'Filters',
  'task.createtask.filters.tooltip': 'Each category should have at least one filter',
  'task.createtask.validation.createforonboardeesstartingfromerror':
    'Make sure to select a date if "Create only for onboardees starting on or after" is checked.',
  'task.createtask.validation.titleerror': "Task title's missing",
  'task.createtask.validation.descriptionerror': "Task description's missing",
  'task.createtask.validation.deadlineerror': "Task deadline's missing",
  'task.createtask.validation.assigneeerror': "Task assignee's missing",
  'task.createtask.validation.assigneeemailvalidation': 'Enter a valid email address',
  'task.createtask.validation.filteritemserror': 'Please select 1 filter item for each filter',
  'task.createtask.validation.descriptionerrorlength': 'A maximum of 1000 characters is allowed',
  'task.filters.selectall': 'Select all',
  'task.filters.invalidfilterstask':
    "We've deleted some filters from the app, so please review the task and save it with the correct filters.",
  'task.table.taskname': 'Task Name',
  'task.table.duedate': 'Due Date',
  'task.notification.error': 'Error',
  'task.notification.success': 'Success',
  'task.notification.tasksavingerror': 'Error saving task',
  'task.notification.tasksavingsuccess': "Task's saved",
  'task.notification.errorcannotgetfilters': 'Unable to get the filters',
  'task.topbar.savetask': 'Save task',
  'task.topbar.edittask': 'Edit task',
  'task.topbar.savingstatusfalse': 'Saved',
  'task.topbar.savingstatustrue': 'Saving',
  'task.topbar.savingstatusfailed': 'Saving failed',
  'task.info.filtercreatetask': 'Each category should have at least one filter',
  'task.createtask.taskdescription': 'Task description',
  'task.notification.errorcannotgetlanguages': 'Unable to get the languages',
  'task.tasksassignee.showless': 'Show Less',
  'task.tasksassignee.showmore': 'Show More',
  'task.button.active': 'Active',
  'task.button.cancelled': 'Cancelled',
  'task.button.completed': 'Completed',
  'task.button.duedate': 'Due date',
  'task.button.onboardeestartdate': 'Onboardee Start Date',
  'task.createtask.validation.keycontactnotassigned.offboardee':
    'Not assigned to the offboardee. You can add a key contact in the offboardee profile.',
  'task.button.offboardeeenddate': 'Offboardee End Date',
  'task.button.employeeenddate': 'Employee Target Date',
  'task.createtask.validation.offboardeeerror': "Task for offboardee's missing",
  'task.title.employee': 'Employee',
  'task.title.description': 'Description',
  'task.title.onboardee': 'Onboardee',
  'task.title.assignedto': 'Assigned To',
  'onboardee.excludetasks':
    'Exclude from the tasks created in the Library (exception: custom tasks for a specific onboardee)',
  'onboardee.excludetasksoffboardee':
    'Exclude from the tasks created in the Library (exception: custom tasks for a specific offboardee)',
  'onboardee.overview.createoffboardee': 'Create offboardee',
  'onboardee.overview.enddate': 'End date',
  'onboardee.overview.onboardees': 'Onboardees',
  'onboardee.overview.offboardees': 'Offboardees',
  'onboardee.overview.nodatafoundoffboardee': 'No offboardees here yet',
  'onboardee.view.profile.title.offboardee': 'Offboardee profile',
  'onboardee.view.profile.introduction': 'There are no introduction for this onboardee yet',
  'onboardee.view.profile.introduction.offboardee': 'There are no goodbye message for this offboardee yet',
  'onboardee.view.introduction.title.offboardee': 'Goodbye message for offboardee',
  'onboardee.edit.profile.success': "Onboardee's updated",
  'onboardee.edit.profile.success.offboardee': "Offboardee's updated",
  'onboardee.user.mobilenumber': 'Mobile number',
  'onboardee.user.facebookurl': 'Facebook link',
  'onboardee.user.twitterurl': 'Twitter link',
  'onboardee.user.missingfilters': 'There are no filters here yet',
  'onboardee.user.gender': 'Gender',
  'onboardee.user.profile': 'Profile picture',
  'onboardee.user.officenumber': 'Office number',
  'onboardee.user.linkedinurl': 'LinkedIn',
  'onboardee.user.jobtitle': 'Job Title',
  'onboardee.user.location': 'Location',
  'onboardee.user.customurl': 'Custom URL',
  'onboardee.user.roles': 'Roles',
  'onboardee.user.edituser': 'Edit user details',
  'onboardee.user.resetpassword': 'Reset password',
  'onboardee.user.fullprofile': 'Go to full profile',
  'onboardee.user.resetpasswordinstructions': "Are you sure you want to reset {name}'s password?",
  'onboardee.user.resetpasswordsuccess': '{firstName} will receive an email with password reset instructions',
  'onboardee.user.greeting': 'Great job',
  'onboardee.user.resendactivationlink': "We've sent an email to {email} with activation instructions",
  'onboardee.user.closeonboardingperiod': 'Are you sure you want to close the onboarding period?',
  'onboardee.user.closeoffboardingperiod': 'Are you sure you want to close the offboarding period?',
  'onboardee.user.backtoprofile': 'Back to profile',
  'onboardee.user.viewtext': 'View profile',
  'onboardee.user.closeonboardinginstructions':
    "You are about to close this employee's onboarding period. The onboardee will no longer receive any timed content or email updates.",
  'onboardee.user.closeoffboardinginstructions':
    "You are about to close this employee's offboarding period. The offboardee will no longer receive any timed content or email updates.",
  'onboardee.user.additionalcloseonboardinginstructions': 'This action cannot be undone',
  'onboardee.user.activationdate': 'Activation date',
  'onboardee.user.accountstatus': 'Account status',
  'onboardee.user.activationcode': 'Activation code',
  'onboardee.user.activationlinksent': 'Last activation link sent',
  'onboardee.user.nokeycontactassigned': 'No key contacts are assigned',
  'onboardee.user.deleteuser': 'Delete User',
  'onboardee.user.deleteuserconfirmation': 'Are you sure you want to delete {user} user?',
  'onboardee.user.userdeleted': 'User has been deleted',
  'onboardee.user.allexceptclosed': 'All (except closed)',
  'onboardee.user.all': 'All',
  'onboardee.user.showonlyemployeeswithmissingfilters': 'Show only employees with missing filters',
  'onboardee.user.hideemployeeswithadminroles': 'Hide employees with admin roles',
  'onboardee.user.copyactivationlink': 'Copy Activation Link',
  'onboardee.user.activatedon': 'Activated on',
  'onboardee.user.lastinvitesent': 'Last invite sent',
  'onboardee.user.activated': 'Activated',
  'onboardee.user.notactivated': 'Not Activated',
  'onboardee.user.onboardingclosed': 'Onboarding closed',
  'onboardee.user.offboardingclosed': 'Offboarding closed',
  'onboardee.user.activationlinkcopied': 'Activation link copied',
  'onboardee.user.talentechid': 'Talentech ID',
  'onboardee.user.work': 'Work',
  'onboardee.user.resettwofactorauth': 'Reset Authentication Setup',
  'onboardee.user.includedeleted': 'Include Deleted',
  'onboardee.user.includeclosed': 'Include Closed',
  'onboardee.user.requiredemail': 'Note: At least one email address is required to save changes.',
  'onboardee.validation.incorrectemailerror': 'Please enter the correct email address',
  'onboardee.validation.noemailprovided': 'Please fill at least one of the email fields',
  'onboardee.validation.emailerror': "Information's missing",
  'onboardee.vieweditbuttontext': 'View/Edit',
  'onboardee.vieweditprofile': 'View/Edit profile',
  'onboardee.closeonboardingperiod': 'Close onboarding period',
  'onboardee.closeoffboardingperiod': 'Close offboarding period',
  'onboardee.searchinputplaceholder': 'Search',
  'onboardee.overview.dayslater': 'in {days} days',
  'onboardee.overview.daylater': 'in {days} day',
  'onboardee.overview.daysago': '{days} days ago',
  'onboardee.overview.today': 'Today',
  'onboardee.overview.incompleteprofiles': 'Incomplete onboardee profiles',
  'onboardee.overview.subtitleincompleteprofiles': 'Incomplete onboardee profiles',
  'onboardee.overview.inactiveonboardees': 'Inactive onboardees',
  'onboardee.overview.subtitleinactiveonboardees': 'Inactive onboardees',
  'onboardee.overview.updatenow': 'Update now',
  'onboardee.overview.resendactivationemail': 'Resend activation email',
  'onboardee.overview.createonboardee': 'Create onboardee',
  'onboardee.overview.addonboardee': 'Add onboardee',
  'onboardee.overview.addoffboadee': 'Add offboardee',
  'onboardee.overview.nodatafound': 'No onboardees found',
  'onboardee.overview.actions': 'Actions',
  'onboardee.create.firstname': 'First name',
  'onboardee.create.lastname': 'Last name',
  'onboardee.create.startdate': 'Start date',
  'onboardee.create.email': 'Email',
  'onboardee.create.personalemail': 'Personal email',
  'onboardee.create.workemail': 'work email',
  'onboardee.create.createbuttontext': 'Create',
  'onboardee.create.cancelbutton': 'Cancel',
  'onboardee.create.newoffboardee': 'New offboardee',
  'onboardee.create.selectstartdate': 'Select start date',
  'onboardee.create.selectenddate': 'Select end date',
  'onboardee.create.startdatefrom': 'Start date: from',
  'onboardee.create.startdateto': 'Start date: to',
  'onboardee.create.enddatefrom': 'End date: from',
  'onboardee.create.enddateto': 'End date: to',
  'onboardee.create.validation.emailerror': 'Please fill in a valid email address',
  'onboardee.create.validation.keycontacterror': 'Please select a key contact from the list',
  'onboardee.create.validation.firstnameerror': "Information's missing",
  'onboardee.create.validation.lastnameerror': "Information's missing",
  'onboardee.create.validation.startdate': "Information's missing",
  'onboardee.create.validation.filteritemserror': 'Please fill in at least one filter in each category',
  'onboardee.create.newonboardee': 'New onboardee',
  'onboardee.create.userdetails': 'User details',
  'onboardee.create.success': "Onboardee's created",
  'onboardee.create.success.offboardee': "Offboardee's created",
  'onboardee.validation.novalidkeycontact': 'Please select a key contact from the list',
  'onboardee.view.form.activationdate': 'Activation Date',
  'onboardee.view.form.phonenumber': 'Phone number',
  'onboardee.view.form.functionincompany': 'Function',
  'onboardee.view.profile.title': 'Onboardee profile',
  'onboardee.view.keycontact.title': 'Keycontacts',
  'onboardee.view.editbutton': 'Edit',
  'onboardee.view.savechanges': 'Save changes',
  'onboardee.view.cancel': 'Cancel',
  'onboardee.view.introduction.title': 'Onboardee introduction',
  'onboardee.view.pulsechecks.title': 'Pulse checks',
  'onboardee.view.quizzes.title': 'Quizzes',
  'onboardee.view.download': 'Download',
  'onboardee.view.form.title': 'Forms',
  'onboardee.noonboardeesyet': 'No onboardees here yet',
  'onboardee.nooffboardeesyet': 'No offboardees here yet',
  'onboardee.addcontentforastart': 'But go ahead and add some for a start!',
  'onboardee.noforms': 'There are no forms for this onboardee yet',
  'onboardee.noquizz': 'There are no quizes for this onboardee yet',
  'onboardee.nopulsechecks': 'There are no pulse checks for this onboardee yet',
  'user.view.profile.title': 'User profile',
  'user.create.newuser': 'New user',
  'user.create.users': 'Users',
  'user.create.success': 'User created succesfully',
  'user.edited.success': 'User details updated succesfully',
  'user.edited.error': 'Error updating the data',
  'user.empty.header': 'No users here yet',
  'user.empty.subheader': 'But go ahead and add some for a start!',
  'user.create.usersoverview': 'Users overview',
  'user.overview.onlyuserskeycontact': 'Only users who are key contacts',
  'user.overview.onlyusersnotkeycontact': 'Only users who are not key contacts',
  'onboardee.view.entriesstatus': 'Showing {start} to {end} of {total} entries',
  'bulkwizard.config.start.actions': 'Select action: Step 2 of 4',
  'bulkwizard.config.start.select': 'Select rows for bulk action: Step 1 of 4',
  'bulkwizard.config.finish.confirm': 'Confirm choices: Step 4 of 4',
  'bulkwizard.common.info':
    'Bulk operations wizard can be used to perform specific operation on multiple onboardees or users.',
  'bulkwizard.common.infooffboarding':
    'Bulk operations wizard can be used to perform specific operation on multiple offboardees or users.',
  'bulkwizard.common.actions': 'Actions',
  'bulkwizard.common.details': 'Details: Step 3 of 4',
  'bulkwizard.common.confirmationquestion': 'Are you sure you want to proceed?',
  'bulkwizard.bulkoperations': 'Bulk operations',
  'bulkwizard.activated': 'Activated',
  'bulkwizard.firstname': 'First name',
  'bulkwizard.lastname': 'Last name',
  'bulkwizard.onboardee.startdate': 'Start date',
  'bulkwizard.onboardee.enddate': 'End date',
  'bulkwizard.selected': 'Selected',
  'bulkwizard.email': 'Email',
  'bulkwizard.keycontact': 'Key contact',
  'bulkwizard.selectall': 'Select all',
  'bulkwizard.notification.success': 'Bulk operation has been performed',
  'bulkwizard.notification.selectdetail': 'Detail must be chosen!',
  'bulkwizard.notification.selectaction': 'Action must be selected!',
  'bulkwizard.functionincompany': 'Job title',
  'bulkwizard.location': 'Location',
  'bulkwizard.gender': 'Gender',
  'bulkwizard.customurl': 'Custom URL',
  'bulkwizard.email.selectemail': 'Select email to send',
  'bulkwizard.proceedtoconfirmation': 'Proceed to confirmation',
  'bulkwizard.common.bulkoperationfinished': 'Bulk operation finished',
  'bulkwizard.filters': 'Filters',
  'bulkwizard.deleteoldroles': 'Clear existing roles',
  'bulkwizard.deleteoldfilters': 'Clear existing filters',
  'bulkwizard.autocompleteerror': 'User doesn’t exist',
  'bulkwizard.emailsentwarning':
    'You’re going to send out invitations to the admin, please make sure this is correct. If you would like to send out invitations to the app instead, you can do so under ‘Onboardees’.',
  'bulkwizard.autocompletehaserrors': 'You have chosen a user who does not exist, please choose a user from the list',
  'bulkwizard.deleteoldfiltersinfo':
    "Filters will be added to the users' existing filters by default, if you want to replace their existing filters please check 'Clear existing filters' below.",
  'bulkwizard.addroles': 'Add roles',
  'bulkwizard.deleteoldrolesinfo':
    "Roles will be added to the users' existing roles by default, if you want to replace their existing roles please check 'Clear existing roles' below.",
  'bulkwizard.contactgroupinfo':
    'Mark the contact role(s) you would like to update, then select the new contact person in the field below. If you leave the field empty, the role will be cleared.',
  'bulkwizard.users.emptylist':
    'There are errors in the recently uploaded excel. Please, cancel this operation, upload the excel again and click on export (yellow button) to check which errors were found. If you have questions, please send an email to support@talmundo.com',
  'bulkwizard.send.email': 'Send email',
  'bulkwizard.edit.selected': 'Edit selected',
  'bulkwizard.delete.selected': 'Delete selected',
  'bulkwizard.update.filters': 'Update filters',
  'bulkwizard.close.onboarding.period': 'Close onboarding period',
  'bulkwizard.close.offboarding.period': 'Close offboarding period',
  'bulkwizard.close.offboarding.success': 'Offboarding period has been closed',
  'bulkwizard.close.onboarding.success': 'Onboarding period has been closed',
  'bulkwizard.assign.key.contacts': 'Assign key contacts',
  'bulkwizard.assign.roles': 'Assign roles',
  'bulkwizard.send.invitation': 'Send invitation',
  'bulkwizard.reset.password': 'Reset password',
  'bulkwizard.type.search': 'Type to search',
  'bulkwizard.no.elements.found': 'Oops! No elements found. Consider changing the search query.',
  'bulkwizard.importexcel': 'Import using excel',
  'bulkwizard.excelexport': 'Export using Excel',
  'bulkwizard.excelreport': 'Report using Excel',
  'bulkwizard.detailedexport': 'Detailed export',
  'common.gender.female': 'Female',
  'common.gender.male': 'Male',
  'common.notspecified': 'Not specified',
  'email.log.content': 'Content',
  'email.log.contentcode': 'Content code',
  'email.log.email': 'Email',
  'email.log.info.body': 'Message',
  'email.log.info.status': 'Status',
  'email.log.info.sender': 'Sender',
  'email.log.info.smtpserver': 'SMTP server',
  'email.log.info.subject': 'Subject',
  'email.log.info': 'Info',
  'email.log.receivers': 'Receivers',
  'email.log.statuses': 'Statuses',
  'email.log.table.response': 'Response message',
  'email.log.templatename': 'Email template',
  'email.log.statusmessage': 'Status message',
  'email.log.title': 'Email log',
  'email.log.unknown': 'Unknown',
  'email.log.modulename': 'Module item',
  'email.log.onboardeename': 'Employee name',
  'email.log.success': 'Success',
  'email.log.failed': 'Failed',
  'email.log.inprogress': 'In progress',
  'email.log.skipped': 'Skipped',
  'email.log.initiated': 'Initiated',
  'email.log.messageid': 'Message ID',
  'email.log.to': 'To',
  'email.log.from': 'From',
  'email.log.subject': 'Subject',
  'email.log.correlationid': 'Correlation ID',
  'email.log.redirecttologsv2hint': 'You\'ll find the updated email log in "Logging", "Email logging v2".',
  'audit.log.title': 'Auditing',
  'audit.log.componentname': 'Component Name',
  'audit.log.action': 'Action',
  'audit.log.action.custom': 'Custom',
  'audit.log.action.insert': 'Insert',
  'audit.log.action.login': 'login',
  'audit.log.action.passwordupdate': 'PasswordUpdate',
  'audit.log.action.export': 'Export',
  'notification.error': 'Something went wrong!',
  'notification.comment.success': 'Comment Added',
  'ui.lastupdatedate': 'Last update date',
  'ui.smtpstatus': 'SMTP status',
  'ui.error': 'Error',
  'ui.actions': 'Actions',
  'ui.back': 'Back',
  'ui.cancel': 'Cancel',
  'ui.close': 'Close',
  'ui.create': 'Create',
  'ui.creating': 'Creating',
  'ui.date': 'Date',
  'ui.delete': 'Delete',
  'ui.details': 'Details',
  'ui.done': 'Done',
  'ui.edit': 'Edit',
  'ui.entries': 'Showing {start} to {end} of {total}',
  'ui.filterplaceholder': 'Filter Table...',
  'ui.next': 'Next',
  'ui.noresults': 'No Results',
  'ui.previous': 'Previous',
  'ui.resend': 'Resend',
  'ui.search': 'Search',
  'ui.status': 'Status',
  'ui.submit': 'Submit',
  'ui.update': 'Update',
  'ui.translate': 'Translate',
  'ui.colorpicker.primary': 'Primary',
  'ui.colorpicker.secondary': 'Secondary',
  'ui.colorpicker.white': 'White',
  'ui.colorpicker.black': 'Black',
  'ui.colorpicker.custom': 'Custom',
  'ui.ipaddress': 'Ip Address',
  'ui.more': 'more',
  'ui.show': 'show',
  'ui.nomatchingresults': 'Nothing here',
  'ui.nomatchingresultsmessage': 'Either this list is empty, or nothing matches the current filters.',
  'webhook.common.active': 'Active',
  'webhook.common.auth': 'Authentication',
  'webhook.common.availableproperties': 'Available Properties',
  'webhook.common.description': 'Description',
  'webhook.common.event': 'Event',
  'webhook.common.example': 'Example',
  'webhook.common.name': 'Name',
  'webhook.common.payload': 'Payload',
  'webhook.common.properties': 'Properties',
  'webhook.common.property': 'Property',
  'webhook.common.requestmethod': 'Request Method',
  'webhook.common.triggers': 'Triggers',
  'webhook.common.type': 'Type',
  'webhook.common.formsselect': 'Select form',
  'webhook.common.triggeringmodule': 'Assigned module',
  'webhook.common.triggeringform': 'Assigned form',
  'webhook.common.url': 'URL',
  'webhook.config.create.title': 'New webhook',
  'webhook.config.edit.title': 'Edit Webhook',
  'webhook.config.title': 'Webhooks',
  'webhook.log.auth.details': 'Authentication details',
  'webhook.log.auth.type': 'Authentication type',
  'webhook.log.body.empty': 'Empty',
  'webhook.log.body.heading': 'Message',
  'webhook.log.headers.header': 'Header',
  'webhook.log.headers.heading': 'Headers',
  'webhook.log.headers.none': 'None',
  'webhook.log.headers.value': 'Value',
  'webhook.log.info.heading': 'Info',
  'webhook.log.info.targeturl': 'Target URL',
  'webhook.log.info.title': 'Title',
  'webhook.log.request.heading': 'Request',
  'webhook.log.responses.heading': 'Responses',
  'webhook.log.responses.showresponse': 'Show response',
  'webhook.log.table.duration': 'Duration',
  'webhook.log.table.errormessage': 'Error Message',
  'webhook.log.table.response': 'Response',
  'webhook.log.table.success': 'Success',
  'webhook.log.table.time': 'Time',
  'webhook.log.title': 'Webhook log',
  'webhook.log.webhook.heading': 'Webhook',
  'webhook.notification.created': "Webhook's created",
  'language.czech': 'Czech',
  'language.danish': 'Danish',
  'language.german': 'German',
  'language.greek': 'Greek',
  'language.english': 'English',
  'language.spanish': 'Spanish',
  'language.finnish': 'Finnish',
  'language.french': 'French',
  'language.hungarian': 'Hungarian',
  'language.armenian': 'Armenian',
  'language.italian': 'Italian',
  'language.japanese': 'Japanese',
  'language.georgian': 'Georgian',
  'language.kazakh': 'Kazakh',
  'language.kyrgyz': 'Kyrgyz',
  'language.norwegian': 'Norwegian',
  'language.dutch': 'Dutch',
  'language.polish': 'Polish',
  'language.portugese': 'Portugese',
  'language.romanian': 'Romanian',
  'language.russian': 'Russian',
  'language.slovakian': 'Slovakian',
  'language.serbian': 'Serbian',
  'language.swedish': 'Swedish',
  'language.turkish': 'Turkish',
  'language.ukranian': 'Ukranian',
  'language.chinese': 'Chinese',
  'language.taiwanese': 'Taiwanese',
  'confirmchangeemail.title': 'Confirm Email Change',
  'counter.atwork': '{days} on the job',
  'counter.afterwork': '{days} after final day',
  'counter.day': 'day',
  'counter.days': 'days',
  'counter.tostart': '{days} to start',
  'counter.tolast': '{days} to last day',
  'dashboard.introduceyourself': 'Get started',
  'dashboard.progress': 'Progress',
  'dashboard.progressinfo':
    "Finished all your tasks but still don't get {percentage}? Don't worry, more tasks will be unlocked as you progress, and you'll reach {percentage} eventually.",
  'dashboard.taskcount': '{count} task ::: {count} tasks',
  'dashboard.tasks':
    "{taskCount} is waiting for you, complete this task and make sure you're fully prepared. ::: {taskCount} are waiting for you, complete these tasks and make sure you're fully prepared.",
  'dashboard.title': 'Dashboard',
  'dashboard.welcome': 'Welcome back, {name}!',
  'dashboard.welcomefirsttime': 'Welcome, {name}!',
  'dashboard.nocontentavailable': 'This content is not available. Please refresh or try again later.',
  'dashboard.nocontenttoshow':
    'There is no content available for you at the moment. Please, communicate this to your HR contact person.',
  'footer.privacypolicy': 'Privacy Policy',
  'form.maxchar': 'Max char',
  'form.rating.numberscale': 'Number scale',
  'form.rating.starscale': 'Star scale',
  'form.rating.happyscale': 'Happy scale',
  'form.placeholder': 'Placeholder',
  'form.radio': 'radio',
  'form.checkbox': 'checkbox',
  'form.select': 'select',
  'form.showif': 'Show if',
  'form.otheroption': 'Other option',
  'form.required': 'Required',
  'form.requiredfield': "This field's missing information",
  'form.requirediban': 'Sorry, invalid IBAN',
  'form.shortanswer': 'Short answer',
  'form.requirevalidation': 'Require validation',
  'form.condition': 'Condition',
  'form.selectsection': 'Select section',
  'form.selectquestion': 'Select question',
  'form.selectvalue': 'Select value',
  'form.addcondition': 'Add condition',
  'form.sectiontitle': 'Section title',
  'form.sectiondescription': 'Section description',
  'form.option': 'Option',
  'form.group.addanotherelement': 'Add another element',
  'form.verygood': 'Very good',
  'form.verybad': 'Very bad',
  'form.gdpr.label': 'I agree to allow Talmundo to store and process my personal data.',
  'form.gdpr.text':
    "This is the GDPR privacy part... If you’d like to see how we’re committed to your privacy here is our full <a href='/privacy-policy' target='_blank'>Privacy Policy</a>. Otherwise, you probably already know why we would like you to tick the following consent box:",
  'form.addcomment': 'Add Comment',
  'form.addnewitem': 'Add',
  'form.clicktoupload': 'Click to Upload',
  'form.comment': 'Comment',
  'form.complete': 'Complete Form',
  'form.confirmpasswordplaceholder': 'Confirm Password',
  'form.currentpasswordplaceholder': 'Current Password',
  'form.deselectlabel': 'Press enter to delete',
  'form.downloadpdf': 'Download PDF',
  'form.email': 'Email',
  'form.entercurrentpasswordtext': 'Enter Your Current Password to Confirm *',
  'form.errors': 'There are errors on the form. Please fix them before continuing.',
  'form.firstname': 'First Name',
  'form.form': 'Form',
  'form.gender': 'Gender',
  'form.gotoform': 'Go to form',
  'form.graduationdateplaceholder': 'Graduation date',
  'form.jobtitle': 'Job Title',
  'form.language': 'Language',
  'form.lastname': 'Last Name',
  'form.location': 'Location',
  'form.name': 'Name',
  'form.password': 'Password',
  'form.passwordplaceholder': 'Password',
  'form.passwordstrength': 'Password Strength',
  'form.phone': 'Phone',
  'form.reminder':
    'We\'re missing some information about you, so it would be great if you can fill out the "{title}" form as soon as you have the opportunity.',
  'form.selectedlabel': 'Selected',
  'form.selectlabel': 'Press enter to select',
  'form.startdateplaceholder': 'Start date',
  'form.text': 'Text',
  'form.thanks': 'Thank you for sending your form',
  'form.upload': 'Upload',
  'form.video': 'Video',
  'forms.addanswer': 'Add answer',
  'forms.addform': 'Add Form',
  'forms.address': 'Address',
  'forms.address2': 'Address 2',
  'forms.addressfield': 'Address field',
  'forms.addsection': '+ Add Section',
  'forms.administrativeformsdisabled':
    'Please, go back and fill in the form embedded in the page. If you don’t see it, reach out to your contact person.',
  'forms.buttonconfirmdelete': 'Delete Form',
  'forms.buttoncreateform': 'Create form',
  'forms.buttonduplicateform': 'Duplicate form',
  'forms.choice': 'Choice',
  'forms.chooseform': 'Choose one of the forms from below',
  'forms.chooseformfromlibrary': 'Select a form from your library',
  'forms.city': 'City',
  'forms.closeform': 'Close Form',
  'forms.completedbyfixed': 'Completed by (fixed date)',
  'forms.completedbyrelative': 'Completed by (relative to start date)',
  'forms.confirmdeleteform': 'Are you sure you want to delete this form',
  'forms.confirmdeleteformerror': 'Error deleting form',
  'forms.country': 'Country',
  'forms.countryfield': 'Country field',
  'forms.datefield': 'Date field',
  'forms.deletechoices': 'Delete option',
  'forms.deletequestions': 'Delete question',
  'forms.deletesections': 'Delete section',
  'forms.descriptionform': 'Please describe the form in maximum 255 characters',
  'forms.discarddraft': 'Discard Draft',
  'forms.displayaddress2': 'Display Address 2',
  'forms.displayprovince': 'Display Province',
  'forms.duplicatechoices': 'Duplicate option',
  'forms.duplicateformtitle': 'Duplicate form',
  'forms.duplicatequestions': 'Duplicate question',
  'forms.duplicatesections': 'Duplicate section',
  'forms.editaftersubmission': 'Edit after submit',
  'forms.editformsettings': 'Edit Form Settings',
  'forms.emailfield': 'Email field',
  'forms.emailsuggestion': 'Did you mean:',
  'forms.filesremaining': 'up to {maxFiles} files ({remainData}) remaining',
  'forms.maxsize': 'Files exceed the size limit',
  'forms.formsplaceholdername': 'Form name...',
  'forms.formsubmitted': "Awesome, you've finished the form!",
  'forms.gdprfield': 'GDPR field',
  'forms.gotoformeditor': 'Go to the form editor to change this form',
  'forms.group': 'Group',
  'forms.ibanfield': 'IBAN field',
  'forms.importtranslations': 'Import Translations',
  'forms.insertcomponent': 'Insert component',
  'forms.insertcomponentmodaltitle': 'Insert a form component',
  'forms.insertcomponenttitle': 'What kind of component would you like to use?',
  'forms.mostused': 'Most used',
  'forms.mostusedcomponent': 'Your most used components',
  'forms.moveandrearange': 'Move and rearange',
  'forms.movequestion': 'Move question',
  'forms.movequestionfromto': 'Move question from {sectionTitle} to:',
  'forms.movequestions': 'Move question',
  'forms.nodescription': 'There is no description for this form.',
  'forms.noforms': 'No forms found',
  'forms.notallowedtosubmit': 'Looks like you already filled in this form. No need to fill it again.',
  'forms.numberfield': 'Number field',
  'forms.numerical': 'Numerical',
  'forms.openanswer': 'Open answer',
  'forms.phonefield': 'Phone field',
  'forms.postalcode': 'Postal Code',
  'forms.presetfields': 'Preset fields',
  'forms.presetfieldssubtitle': 'We defined a couple of most used fields',
  'forms.previewdescription': 'Description of form',
  'forms.province': 'Province',
  'forms.publishsuccess': 'Form published',
  'forms.rating': 'Rating',
  'forms.rearrangechoices': 'Rearrange option',
  'forms.rearrangequestions': 'Rearrange question',
  'forms.rearrangesections': 'Rearrange section',
  'forms.regexpfield': 'Regex field',
  'forms.reminderdaysbeforedue': 'Remind x days before due date',
  'forms.repeatingarea': 'Repeating area',
  'forms.requiredgroup': "This group's missing",
  'forms.savedchangessuccess': 'Changes saved',
  'forms.sendreminder': 'Send completed by reminder',
  'forms.setupnewform': 'Setup your new form',
  'forms.thisformisdue': 'This form should be completed...?',
  'forms.unconfirmedchangestitle': 'You have unsaved changes in your form.',
  'forms.uploadfile': 'Upload file',
  'forms.uploadzip': 'upload 1 zip file',
  'forms.addquestion': 'Add Question',
  'forms.question': 'Question',
  'forms.section': 'Section',
  'forms.addquestionorsection': 'Add question or section',
  'forms.addsectionmarker': 'Add Section',
  'forms.uploadfileformat': 'Please upload the files with extensions {extensions}',
  'forms.countryselectorlabel': 'Country code',
  'forms.countryselectorerror': 'Choose a country',
  'forms.phonenumberlabel': 'Phone number',
  'forms.example': 'Example',
  'gender.female': 'Female',
  'gender.male': 'Male',
  'gender.unknown': 'Not Specified',
  'general.acceptprivacypolicy': 'I accept the Privacy Policy',
  'general.active': 'Active',
  'general.closed': 'Closed',
  'general.add': 'Add',
  'general.back': 'Back',
  'general.cancel': 'Cancel',
  'general.change': 'Change',
  'general.collapse': 'Collapse',
  'general.contactsupport': 'Contact Support',
  'general.dashboard': 'Dashboard',
  'general.delete': 'Delete',
  'general.discardchanges': 'Discard Changes',
  'general.edit': 'Edit',
  'general.expand': 'Expand',
  'general.exporttranslations': 'Export Translations',
  'general.importtranslations': 'Import Translations',
  'general.inactive': 'Inactive',
  'general.library': 'Library',
  'general.loading': 'Loading',
  'general.nextstep': 'Next step',
  'general.no': 'No',
  'general.ok': 'OK',
  'general.readmore': 'Read more',
  'general.reset': 'Reset',
  'general.returntodashboard': 'Back to dashboard',
  'general.save': 'Save',
  'general.savesettings': 'Save Settings',
  'general.seconds': 'seconds',
  'general.select': 'Select',
  'general.send': 'Send',
  'general.showall': 'Show all',
  'general.submittedon': 'Submitted on',
  'general.termsandconditions': 'Terms and Conditions',
  'general.translations': 'translations',
  'general.unsavedchangestext': 'Do you want to save these changes?',
  'general.update': 'Update',
  'general.uploadtranslations': 'Upload Translations',
  'general.view': 'Next',
  'general.yes': 'Yes',
  'general.has': 'has',
  'general.have': 'have',
  'general.page': 'page',
  'general.pages': 'pages',
  'general.quickview': 'Quick view',
  'general.pagination': '{entries} entries',
  'general.notavailable': 'Not Available',
  'info.company':
    "Company Pages contain all the information you need to know about the company and the onboarding process. They are updated automatically with new information as your start day approaches, so be sure to check back regularly or keep an eye on notifications in your mailbox!You can go back to the Company Pages main menu by clicking on 'Company Pages' in the breadcrumbs at the top, or click on 'Dashboard', to go back to your Dashboard.",
  'info.companyoffboarding':
    'Company Pages contain all the information you need to know about the company and your offboarding process. They are updated automatically with new information as your final day approaches, so be sure to check back regularly or keep an eye on notifications in your mailbox! ',
  'info.dashboard':
    'Here’s where you’ll find everything you need about your next steps within the organization. Keep an eye on your tasks and return regularly to discover new content.',
  'info.dashboardoffboarding':
    "Your dashboard provides a birds-eye view of your offboarding journey. At the top you'll see two icons, the first is on the left and is a navigation menu of all the content modules in the app, and the second on the right is where you can change your account settings and log out. Below the top bar you'll find an overview of your progress: days until your final day, percentage of activities done in the app, and a list of your pending and completed tasks. Keep scrolling down to explore all the content in the app - P.S. You can add the app to the Home Screen of your phone or tablet with this simple step. First, open the website or web page you want to pin to your home screen. Then: • For Google Chrome: Tap on the option menu right on top, and select ‘Add to Home Screen’.• For Firefox: Tap the menu button right on top, tap the Page option, and tap ‘Add to Home Screen’.• For Safari: Tap the Share button on the browser’s toolbar (i.e. the rectangle with an arrow pointing upward), and ‘Add to Home Screen’",
  'info.introduction':
    "Your new colleagues are excited to meet you! Write (or record, depending on app version) a short introduction about yourself to share with your future manager and team. Share even more by uploading a photo and sharing your social media channels (if you want) so your manager and team can start to get to know you even better.You can always click 'Save' to save a draft of your info before submitting it to your (future) manager.If you click 'Send to your manager', your introduction will be submitted and emailed to your manager right away. You cannot change it once sent.",
  'info.introductionoffboarding':
    "Before you go, you might have some nice things to say to your team. You can use this module to write them a short goodbye message. When you click 'Send', your mesage will be shared with your manager and/or HR and they can then forward it to the rest of the team. You can always click 'Save' to save a draft of your info before submitting it. After sending, it cannot be edited anymore.",
  'info.onboardingfinishedheading': 'Congrats! You’ve completed your onboarding journey!',
  'info.onboardingfinishedheadingoffboarding': 'You’ve completed your offboarding journey.',
  'info.onboardingfinishedtext':
    'We hope that your first months have gone smoothly and you are settling into your role and your team. Good luck with your continuing journey with us!',
  'info.onboardingfinishedtextoffboarding': 'Good luck in all your future endeavors! ',
  'info.pulsecheck':
    "Provide your feedback by completing the surveys in this section. It's easy, just select how many stars you'd give each question, 1 being the lowest and 5 the highest ranking.Some surveys might include open text questions as well, so share your additional feedback by providing your written opinion and ideas.Not ready to send your feedback to your (future) HR Contact quite yet? First select 'Save'. If you select 'Send to HR', your feedback will be submitted and emailed to your HR Contact right away and you won't be able to change it later.",
  'info.pulsecheckoffboarding':
    "Provide your feedback by completing the surveys in this section. It's easy, just select how many stars you'd give each question, 1 being the lowest and 5 the highest ranking.Some surveys might include open text questions as well, so share your additional feedback by providing your written opinion and ideas. Your feedback is important for us, since we're always looking for ways to improve. After you hit 'Send' you can't change your responses anymore.",
  'info.quiz':
    "Quiz time! Test your knowledge about the company by completing the different quizzes about the company, its history, products and/or services, etc.See how much you've learned while exploring the company pages, and check your answers against the correct ones at the end of the quiz.Didn't score as well as you'd like? Don't worry, you can always take the test again.",
  'info.quizoffboarding':
    "Quiz time. Test your knowledge about the company by completing the different quizzes we have prepared. Check your answers against the correct ones at the end of the quiz. Didn't score as well as you'd like? Don't worry, you can always take the test again.",
  'introduction.readytosend': 'Ready to send?',
  'labels.addons': 'Add-ons',
  'labels.options': 'Options',
  'labels.settingdescription': 'Description',
  'labels.settinglabels': 'Labels',
  'labels.settingtitle': 'Title',
  'labels.settingtitlebackgroundcolor': 'Title Background Color',
  'labels.settingtitlecolor': 'Title Color',
  'labels.showaslink': 'Show as link',
  'labels.showgdprtext': 'Show GDPR text',
  'labels.textalignment': 'Text alignment',
  'labels.textcolor': 'Text Color',
  'labels.textstyles': 'Text styles',
  'login.agreetoterms': 'By signing in, I am agreeing to the {termsAndConditions} and {privacyPolicy}',
  'login.alreadyloggedin':
    "Looks like you're already signed in. We'll redirect you in {number} seconds, but if that doesn't work, try to {login.goToDashboard}",
  'login.alreadyregistered': 'Already registered?',
  'login.autoredirect': "We'll redirect you in",
  'login.chooseapp': 'Choose app',
  'login.enteremail': 'Email',
  'login.enterpassword': 'Password',
  'login.forgotyourpassword': 'Forgot your password?',
  'login.gotodashboard': 'go to your dashboard',
  'login.login': 'Sign in',
  'login.logintoyouraccount': 'Sign in to your account',
  'login.recoverpassword': 'Recover password',
  'login.register': 'Register',
  'login.rememberme': 'Remember me',
  'login.changeemail': 'change email',
  'lp.author': 'Author',
  'lp.noenoughtrights':
    'You do not have permission to add this page. Please go to the library & duplicate or reach out to your main administrator.',
  'lp.addtagmessage': 'Please add tags',
  'lp.addtag': 'Add tag by pressing enter',
  'lp.buttonaddlearningpage': 'Add Learning Page',
  'lp.buttonassignpage': 'Assign Page',
  'lp.buttonconfirmdelete': 'Delete Page',
  'lp.buttoncopypage': 'Copy Page',
  'lp.buttoncreatepage': 'Create page',
  'lp.buttonduplicatepage': 'Duplicate',
  'lp.buttonexportpages': 'Export Pages',
  'lp.buttonimportpages': 'Import Pages',
  'lp.buttoninsertcomponent': 'Insert Component',
  'lp.buttonselectimage': 'Select Image',
  'lp.buttonselectimages': 'Select Images',
  'lp.buttonusepage': 'Use Page',
  'lp.changeversion': 'Change Version',
  'lp.choosecreatepage': 'How would you like to start building your page?',
  'lp.chooselearningpagefromlibrary': 'Choose Learning page from library',
  'lp.choosesubtextlibrary': 'Choose from your predefined pages',
  'lp.choosesubtextnewpage': "You're starting with a blank canvas",
  'lp.componentaccordion': 'Accordion',
  'lp.componentaccordionitem': 'Accordion item',
  'lp.componentaccordionvariant': 'Accordion variant',
  'lp.componentcolumns': 'Columns',
  'lp.componentcolumnsitem': 'Columns',
  'lp.componentcolumnsvariant': 'Columns variant',
  'lp.componentform': 'Form',
  'lp.componentheader': 'Header',
  'lp.componentheadervariant': 'Header variant',
  'lp.componentiframe': 'Iframe',
  'lp.componentimage': 'Image',
  'lp.componentimagetext': 'Image with text',
  'lp.componentmanagement': 'Management',
  'lp.componentmap': 'Map',
  'lp.componentmediatext': 'Media with text',
  'lp.componentslalom': 'Slalom',
  'lp.componentslalomvariant': 'Slalom variant',
  'lp.componentstatement': 'Statement',
  'lp.componentstatementvariant1': 'Statement Variant 1',
  'lp.componentstatementvariant2': 'Statement Variant 2',
  'lp.componenttabs': 'Tabs',
  'lp.componenttabsitem': 'Tab item',
  'lp.componenttabsvariant': 'Tabs variant',
  'lp.componenttext': 'Text',
  'lp.componentthreesixty': '360 view',
  'lp.componenttimeline': 'Timeline',
  'lp.componentvideo': 'Video',
  'lp.componentkeycontacts': 'Key contacts',
  'lp.componentkeycontact': 'Key contact',
  'lp.componentkeycontactsitem': 'Key contacts item',
  'lp.componentkeycontactnocontactsfound': 'Please add the "Your contacts" module first',
  'lp.componentkeycontactdisabled': 'No key contacts',
  'lp.confirmdeletelp': 'Are you sure you want to delete page from the module?',
  'lp.confirmdeletelperror': 'Error deleting page',
  'lp.buttonselectpages': 'Select Pages',
  'lp.buttonselectall': 'Select All',
  'lp.buttonunselectall': 'Unselect All',
  'lp.buttondeleteselected': 'Delete Selected',
  'lp.buttonexportselected': 'Export Selected',
  'lp.buttonconfirmdeletepages': 'Delete Pages',
  'lp.deletesuccess': 'Selected learning {pageCount} {pronoun} been deleted',
  'lp.confirmdeletelps': 'Are you sure you want to delete {number} selected {pageCount}?',
  'lp.confirmdeletelpsinfo':
    'Some of the selected pages might be linked to a learning page module. Pages will be deleted from the modules and from the Library. You will not be able to recover these pages.',
  'lp.contentblockpagesettings': 'Page Settings',
  'lp.contentblocksettingactivecolor': 'Active Color',
  'lp.contentblocksettingactivetabcolor': 'Active Tab Color',
  'lp.contentblocksettingactivetitle': 'Active Color',
  'lp.contentblocksettingamountofitems': 'Number of Items',
  'lp.contentblocksettingavailable': 'Becomes Available?',
  'lp.contentblocksettingbackgroundcolor': 'Background Color',
  'lp.contentblocksettingbackgroundimage': 'Background Image',
  'lp.contentblocksettingbarcolor': 'Bar Color',
  'lp.contentblocksettingbutton': 'Button',
  'lp.contentblocksettingbuttoncolor': 'Button Color',
  'lp.contentblocksettingcolor': 'Color',
  'lp.contentblocksettingicon': 'Icon',
  'lp.contentblocksettingimage': 'Image',
  'lp.contentblocksettinginactivecolor': 'Inactive Color',
  'lp.contentblocksettinginactivetitle': 'Inactive Color',
  'lp.contentblocksettingmanagementitem': 'Management Item {number}',
  'lp.contentblocksettingsalignment': 'Alignment',
  'lp.contentblocksettingsiframeplaceholder': 'Paste Google Maps or 360 tour url',
  'lp.contentblocksettingsignature': 'Signature',
  'lp.contentblocksettingslalomitem': 'Slalom Item {number}',
  'lp.contentblocksettingsmapplaceholder': 'Paste Google Maps embed code',
  'lp.contentblocksettingsposition': 'Position',
  'lp.contentblocksettingstextstyles': 'Text Styles',
  'lp.contentblocksettingsubtitle': 'Subtitle',
  'lp.contentblocksettingsubtitlecolor': 'Subtitle Color',
  'lp.contentblocksettingswipeableonmobile': 'Swipeable on Mobile',
  'lp.contentblocksettingtabcolor': 'Tab Color',
  'lp.contentblocksettingtext': 'Text',
  'lp.contentblocksettingtimelineitem': 'Timeline Item {number}',
  'lp.contentblocksettingvideourl': 'Video URL',
  'lp.createnewmodule': 'New module',
  'lp.createnewlearningpage': 'New learning page',
  'lp.cropimage': 'Crop image',
  'lp.chooseimage': 'Choose image',
  'lp.choose5images': 'Choose up to 5 images',
  'lp.deletepage': 'Delete Page',
  'lp.descriptionlearningpage': 'Please describe the page in maximum 400 characters',
  'lp.internaldescriptionlearningpage':
    'Add an internal description for the learning page. This description will only be seen by admins',
  'lp.displayoption': 'Display Option',
  'lp.draft': 'Draft',
  'lp.duplicatepagetitle': 'Duplicate page',
  'lp.editablebyothers': 'Editable by others',
  'lp.editexistingpage': 'Edit Learning Page',
  'lp.editmodule': 'Edit Module',
  'lp.editor': 'Editor',
  'lp.editornoelementselect': 'Select an element to activate this panel',
  'lp.editpagesettings': 'Edit Page Settings',
  'lp.entertranslatemode': 'Enter Translate Mode',
  'lp.existinglearningpages': 'Existing Learning Pages',
  'lp.existingmodules': 'Existing modules',
  'lp.exitpreview': 'Exit Preview',
  'lp.exittranslatemode': 'Exit Translate Mode',
  'lp.filters': 'Filters',
  'lp.fixedratio': 'Fixed Ratio',
  'lp.gotolibrary': 'Go to Library',
  'lp.imageurl': 'Image Url',
  'lp.intranslatemode': 'Translate Mode',
  'lp.invalidcontent': 'This content is not valid',
  'lp.learningpagelabel': 'Label',
  'lp.learningpagelabeldate': 'I want this page to be available...?',
  'lp.learningpageplaceholderlabel': 'Give this page a label...',
  'lp.learningpageplaceholdername': 'Learning page name...',
  'lp.learningpages': 'Learning Pages',
  'lp.learningpagetags': 'Tags',
  'lp.learnmore': 'Learn more',
  'lp.linkedpagesmodule': 'This page is linked to these modules',
  'lp.maxleveltop': 'You need to reorder or remove learning page to add more to the top-level. The maximum is {count}',
  'lp.modulealways': 'Always',
  'lp.modulegrid': 'Grid',
  'lp.modulelabelactive': 'Would you like this page to be active?',
  'lp.modulelabeldate': 'I want this module to be available...?',
  'lp.modulelabelending': '...Ending...',
  'lp.modulelabelfixeddate': 'Fixed Date',
  'lp.modulelabelfullwidth': 'Full Width',
  'lp.modulelabelhalfwidth': 'Half Width',
  'lp.modulelabelname': 'Give this learning page module a unique name',
  'lp.modulelabelrelativedate': 'Relative Date',
  'lp.modulelabelfixedstartdate': 'Fixed start date',
  'lp.modulelabelfixedenddate': 'Fixed end date',
  'lp.modulelabelstarting': '...Starting...',
  'lp.modulelabelswipable': 'How would you like the subpages to appear?',
  'lp.modulelabeltitlecolor': 'What color would you like to give the page title?',
  'lp.modulelabelwidth': 'How would you like to show this module on your dashboard?',
  'lp.moduleplaceholdername': 'Module Name...',
  'lp.modules': 'Modules',
  'lp.moduleswipable': 'Swipable',
  'lp.moduletextafter': 'After',
  'lp.moduletextbefore': 'Before',
  'lp.moduletextfirstworkday': 'First Workday',
  'lp.moduletextlastworkday': 'Last Workday',
  'lp.newcomponentcommon': 'Common',
  'lp.newcomponentprestyled': 'Prestyled',
  'lp.newmodule': 'Create a new learning page module',
  'lp.newtag': '+ New Tag',
  'lp.noimagesfound': 'No images found',
  'lp.nofilesfound': 'No files found',
  'lp.nolearningpagefound': 'No learning page found',
  'lp.nolearningpages': 'There are no learning pages yet to display',
  'lp.nopublishedversions': 'No Published Versions',
  'lp.pagesoverview': 'Pages Overview',
  'lp.preview': 'Preview',
  'lp.previewlibrarypagetitle': 'Preview of your chosen page',
  'lp.publish': 'Publish',
  'lp.radioimagelibrary': 'From Your Library',
  'lp.radioimagescratch': 'Scratch',
  'lp.redo': 'Redo',
  'lp.restoreversion': 'Restore',
  'lp.savechanges': 'Save changes',
  'lp.savemodule': 'Save Module',
  'lp.selectfilter': 'Select Filter',
  'lp.selecticon': 'Select Icon',
  'lp.setupnewpage': 'Setup your new learning page',
  'lp.start': 'Start',
  'lp.startimmediately': 'Start Immediately',
  'lp.startpage': 'Start building your learning page',
  'lp.titlechoosecomponent': 'Insert a component',
  'lp.titlechooselpfromlibrary': 'Select a learning page from your library',
  'lp.treeview': 'Tree View',
  'lp.filterswarningmessage':
    'This learning page does not have all the necessary filters. Please, access it again and add at least one item for each filter category. If you are not able to add the filters yourself, please contact your super admin or our support',
  'lp.treeviewnodata': 'No Learning pages assigned',
  'lp.undo': 'Undo',
  'lp.unsavedchangestitle': 'You have unsaved changes in your learning page.',
  'lp.updatemodule': 'Update Module',
  'lp.uploadimage': 'Upload Image',
  'lp.uploadingimage': 'Uploading Image',
  'lp.url': 'URL',
  'lp.versionback': 'Previously Published Version',
  'lp.versioncurrent': 'Current Published Version',
  'lp.versionhistory': 'Version History',
  'lp.viewmode': 'You are not allowed to edit this page.',
  'lp.daysbeforefirstworkday': 'days before first workday',
  'lp.daysafterfirstworkday': 'days after first workday',
  'lp.titledescription': 'Learning page description',
  'lp.titleinternaldescription': 'Learning page internal description',
  'lp.nodescription': 'No description',
  'lp.hoverfordescription': 'Hover to read the description',
  'lp.componentsecurefiles': 'Files',
  'lp.buttonselectfiles': 'Select Files',
  'lp.contentblocksettingshowastable': 'Show as table',
  'menu.profilesettings': 'Profile Settings',
  'menu.settings': 'Account settings',
  'menu.signout': 'Sign out',
  'notifications.missingfilters':
    'Make sure to select at least 1 filter value in every filter category before exiting or saving, or the page will not be visible.',
  'notifications.accountdeleted': 'Your account has been deleted.',
  'notifications.activationerror':
    'We are having trouble activating your account. Please try again later or contact your administrator.',
  'notifications.activationsuccess': "Account's activated",
  'notifications.addtohomescreen': 'Install this webapp on your device. Tap {icon} and then add to homescreen',
  'notifications.addtohomescreenandroid':
    'Install this webapp on your device. Tap {icon} and then Add to homescreen or Install app',
  'notifications.allset': "You're all set! We're signing you in now",
  'notifications.answerssaved': 'Your answers have been saved!',
  'notifications.changepassworderror':
    'Something went wrong. Please open the link in your email again or request a password reset.',
  'notifications.confirmchangeemail': 'Your email has been changed',
  'notifications.cookiemessage':
    'This website uses cookies to store information on your device that are essential for this site to work. More information can be found in the Privacy Policy.',
  'notifications.dataupdated': 'Your data has been updated!',
  'notifications.didnotanswerallquestions': 'You have not answered all the questions.',
  'notifications.emailalreadyexists': 'A user with that email already exists',
  'notifications.emailchange':
    'Confirm your email change to {email} by following the instructions you have received on your new address.',
  'notifications.emailnotallowed': 'Email not valid',
  'notifications.emailnotfound': 'Email not found',
  'notifications.enterpasswordchosenrecently': 'Entered password was chosen in recent history.',
  'notifications.error': 'Something went wrong, Please try again later.',
  'notifications.erroravailablefeatures': 'Something went wrong when getting the available features',
  'notifications.errorcreatingform': 'Something went wrong when creating the form.',
  'notifications.errorcurrentpasswordincorrect': 'Current password is incorrect',
  'notifications.errordiscarddraftform': 'Something went wrong when discarding the draft.',
  'notifications.errorduplicatingforms': 'Something went wrong when duplicating the forms.',
  'notifications.erroredittingform': 'Something went wrong when editing the form.',
  'notifications.errorformremovedwhileattachedtolp':
    'Form with id {formId} was deleted. The form has also been removed from this Learning Page',
  'notifications.errorgettingcompanydata':
    'Something went wrong when getting your company. Styles might not get applied',
  'notifications.errorgettingfiles': 'Something went wrong when getting the file(s)',
  'notifications.errorgettingform': 'Something went wrong when getting the form.',
  'notifications.errorgettinglp': 'Something went wrong when getting the learning page',
  'notifications.errorgettingforms': 'Something went wrong when getting the forms.',
  'notifications.errorgettingtranslations': 'Something went wrong when getting the translations',
  'notifications.errorimportingtranslations': 'Something went wrong when importing the translations',
  'notifications.errorimportingtranslationsnotmatchingformid': 'You cannot import translations from a different form',
  'notifications.errorimportingtranslationsnotmatchinglpid':
    'You cannot import translations from a different learning page',
  'notifications.errorimportingtranslationsonlyzipallowed': 'Only .zip files are allowed to upload',
  'notifications.errorincorrectfileformatupload': 'The file format is not supported.',
  'notifications.errorsubmitfiles': 'Something went wrong when uploading the file(s)',
  'notifications.errorsubmitform': 'Something went wrong when submitting form',
  'notifications.error2fa': 'Two factor authentication is invalid',
  'notifications.error.accountnotactivated': 'Account not activated.',
  'notifications.exportuserdatasuccess': 'An email has been sent with your personal information.',
  'notifications.imagetobig': 'This image is too big. Try uploading a smaller image (smaller than 1.5Mb)',
  'notifications.importemptyinput': 'You need to select a file to import!',
  'notifications.incorrectfileformat': 'This image format is not supported. Please upload an image of type: {files}.',
  'notifications.introductiondatasaved': 'Your data has been saved! Remember to send it when you are done.',
  'notifications.isrequired': "{field}'s missing",
  'notifications.loginerror':
    "Sorry, we're having trouble signing you in. Make sure your email and password are correct.",
  'notifications.loginsuccess': 'Signed in!',
  'notifications.lplearningpagecreated': "Learning page's created",
  'notifications.lpmodulecreated': 'This module already exists',
  'notifications.lpmoduleupdated': 'The module has been updated',
  'notifications.lppublished': 'Learning page published',
  'notifications.lppublishfail': 'Learning page could not be published',
  'notifications.lpupdated': 'Learning page saved',
  'notifications.lpupdatefail': 'Learning page could not be saved',
  'notifications.missinginvitationcode': 'Missing invitation code',
  'notifications.missinguseremail': 'Missing user email',
  'notifications.missingvideoid': 'Missing video or player id',
  'notifications.noaccess': 'No access',
  'notifications.noonboardingids': 'No onboarding id(s) present. Please contact your administrator',
  'notifications.pagelinkexpired': 'Link has been expired',
  'notifications.pagelinkexpiredtext':
    "The download link has expired or is invalid. It's possible to request a new export under their profile settings.",
  'notifications.pagenotfound': '404 Page Not Found',
  'notifications.pagenotfoundsubtitle': 'Sorry, an error has occured, requested page not found!',
  'notifications.passwordreused': 'Entered password was chosen in recent history. Please provide a new one.',
  'notifications.passwordsdontmatch': "Passwords don't match",
  'notifications.passwordexpired': 'Your password has expired. Please change your password to continue.',
  'notifications.profileupdated': 'Profile settings updated!',
  'notifications.quizdatasaved': 'Quiz data has been saved!',
  'notifications.registrationerror':
    'We are having trouble registering you. Please check that your email and password are correct.',
  'notifications.registrationsuccess':
    'Registration successful! Please check your email and click on the link to activate your account.',
  'notifications.successcreatingform': "Form's created",
  'notifications.successdiscarddraftform': 'Draft version has been discarded',
  'notifications.successduplicatingform': 'Form duplicated',
  'notifications.successedittingform': 'Form edited',
  'notifications.successgettingtranslations': 'Translations have been exported',
  'notifications.successimportingtranslations': 'Translations have been imported',
  'notifications.successsubmitfiles': 'The file(s) have been uploaded',
  'notifications.successsubmitform': 'Form submitted',
  'notifications.timeout': 'Server not responded in time. Please try again later',
  'notifications.triggeredexport': 'Export has been triggered! An email with the learning pages will be sent soon.',
  'notifications.triggeredimport': 'Import has been triggered! An email will be sent when the import is successful.',
  'notifications.updatedpassword': 'Your password has been updated!',
  'notifications.usernamepasswordnotfound': 'Cannot find Username / password',
  'notifications.wrongpictureformat':
    'Wrong format. Please select a different picture (Max File Size: 20mb / Min Height: 200px)',
  'notifications.activationemailsent': 'Activation email sent!',
  'notifications.userrelatedtolinkdoesnotexist': 'User for whom the link was generated no longer exists.',
  'notifications.invalidvalue': 'Provided value was invalid.',
  'notifications.publishedonsave':
    'When editing content that already exists, hitting “save” will publish it directly. For more information please contact support.',
  'notifications.forminvalid': 'Please check and correct the fields marked red',
  'notifications.successresetpassword': 'Password has been reset',
  'notifications.resettwofactorauthsuccess': 'Multifactor authentication has been reset.',
  'notificaitons.confirmresetauthsetup': 'Are you sure you want to reset {name} authentication setup?',
  'push.notifications.allow': "{allowNotificationLink} on your phone, and we'll let you know when we add new content.",
  'push.notifications.allow.link.text': 'Allow notifications',
  'push.notifications.allow.settings.text':
    "To allow notifications, first open your phone's settings app. Then tap notifications, find this app's name in the list, and tap it to tweak your settings. Done!",
  'pagetitles.dashboard': 'Dashboard',
  'password.lowercase': 'At least 1 lowercase letter',
  'password.minimumlength': 'Minimum {amount} characters',
  'password.noenglish': 'No English words',
  'password.numbers': 'At least 1 number',
  'password.ok': 'Password is OK',
  'password.passwordformatincorrect': "Your password doesn't match all requirements",
  'password.specialcharacters': 'At least 1 special character',
  'password.uppercase': 'At least 1 capital letter',
  'privacy.changestext':
    'We reserve the right to modify this statement. We recommend that you review this statement regularly, so that you remain informed of any changes.',
  'privacy.changestitle': 'Changes to this Privacy Statement',
  'privacy.cookiestext':
    'Whenever you visit our website, your IP-address will automatically be stored in our web server logs. This is necessary to enable us to properly manage and troubleshoot the web server. Our standard retention term for server logs is 6 months. When you visit our website for the first time, you will be shown a small pop-up asking you to accept our use of certain cookies and plugins. By clicking ‘accept’ you give us your consent to use all cookies and plugins as described in the pop-up and in this privacy policy. You can control and/or delete cookies as you wish. If you need further explanation or details, please see {url}. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.',
  'privacy.cookiestitle': 'Browsing our website and setting your cookie preferences',
  'privacy.dataprocessing': 'The data we process for you to use our website are:',
  'privacy.dataprocessingemail':
    'Email address, as unique identifier to sign in to the website or to send you notifications on new items in your account.',
  'privacy.dataprocessingform':
    "Next to this, your new employer can add extra forms to our website as well. Talmundo's only processor of the content you decide to provide. We process this information as required by the DPDA. If you fill out a form on the website, the information you provide will be retained for as long as necessary depending on the nature of the form. If necessary, we may collect your location (GPS). If that's the case, you'll be asked to grant consent on beforehand. The location information and other information can also be stored and processed by the provider of the navigation/mapping software, such as Google Maps, but it could also be used by for example Google or Apple itself. We have no control over their actions. We recommend you read the applicable privacy statement of the provider in question.",
  'privacy.dataprocessingname': 'Name, to introduce you to your team or manager.',
  'privacy.dataprocessingprofile':
    'Profile image, for an extra personal touch. The image can be viewed by your employer, manager and colleagues.',
  'privacy.inspectiontext':
    'For any questions or requests about your privacy and our privacy policy, please contact us by e-mail through support@talmundo.com or by traditional mail at the following address:',
  'privacy.inspectiontitle': 'Inspection and Modification of your Data',
  'privacy.intro':
    'Through its online service, *.apps.talmundo.com will process privacy-sensitive or personal data. Talmundo BV values the privacy of its customers and observes due care in processing and protecting personal data. We process data in compliance with the requirements laid down by the Dutch Personal Data Protection Act (DPDA) (“Wet bescherming persoonsgegevens”). Talmundo BV is the party responsible for all data processing. Our data processing is registered with the Dutch Data Protection Authority under number m1573011. In this privacy statement, we will explain which personal data we collect and for which purposes. We recommend that you read it carefully. This privacy statement was last amended on 18-08-2016.',
  'privacy.personaldatatext':
    'By using our service, you are providing certain data to us. This could be personal data. We only retain and use the personal data provided directly by you or for which it is clear that it has been supplied to us to be processed. Although companies can make use of our website, company data is not covered by the DPDA. This privacy statement is primarily directed to those using the website as a private person or as an employee.',
  'privacy.personaldatatitle': 'Use of Personal Data',
  'privacy.purposetext':
    'Which of your specific data is processed by us and why, depends on how you are using our website and what services you are receiving from us.',
  'privacy.purposetitle': 'Our specific purposes',
  'privacy.securityssl':
    'We make use of secure connections (Secure Sockets Layer of SSL) to encrypt all information between you and our website when entering your personal data.',
  'privacy.securitytext':
    'We take security measures to reduce misuse of and unauthorised access to personal data. We take the following measures in particular:',
  'privacy.securitytitle': 'Security',
  'privacy.securityuserpass': 'We need a username and password for access to personal information',
  'privacy.securityusertoken': 'We need a username and sign-in key for access to personal information',
  'privacy.statistics':
    'We do keep statistics on our website, giving us access to individual data. However, we only access individual data insofar as necessary for the purposes set out in this statement.',
  'privacy.thirdpartiesemployer': 'Your (new) employer or employees of the employer.',
  'privacy.thirdpartiesflipbase': 'Flipbase BV, for all video’s you record or send via our website.',
  'privacy.thirdpartiessmtp': 'Our SMTP provider who will send out all informative e-mail messages',
  'privacy.thirdpartiestext':
    'We may provide your data to our partners. These partners are involved in the execution of the agreement. These partners are:',
  'privacy.thirdpartiestitle': 'Providing Data to Third Parties',
  'privacy.thirdpartywebsitestext':
    'This statement is not applicable to third-party websites connected to this website through links. We cannot guarantee that these third parties will handle your personal data in a secure and careful manner. We recommend you read these websites’ privacy statements before making use of these websites.',
  'privacy.thirdpartywebsitestitle': 'Third-party Websites',
  'pulsecheck.warning': 'Warning',
  'pulsecheck.awesome': 'Awesome',
  'pulsecheck.bad': 'Bad',
  'pulsecheck.good': 'Good',
  'pulsecheck.hasunansweredquestions': 'You left some questions open. Are you sure you want to send your answers?',
  'pulsecheck.noquestions': 'No questions yet.',
  'pulsecheck.norating': 'No rating',
  'pulsecheck.typeyouranswer': 'Type your answer...',
  'pulsecheck.verybad': 'Very bad',
  'quiz.butthecorrectanswerswere': ', but the correct answers are',
  'quiz.butthecorrectanswerwas': ', but the correct answer is',
  'quiz.feedback': 'Feedback',
  'quiz.finish': 'Finish',
  'quiz.locked': 'Locked',
  'quiz.next': 'Next',
  'quiz.previous': 'Previous',
  'quiz.question': 'Question',
  'quiz.results': 'Results',
  'quiz.take': 'Take quiz',
  'quiz.takequizagain': 'Take quiz again',
  'quiz.youhaveanswered': 'You have answered',
  'recoverpassword.backtologin': 'go back to the sign-in screen.',
  'recoverpassword.infotext': 'Fill in your email address to recover your password or {backToLogin}',
  'settings.acceptlicense': 'I accept the License Agreement.',
  'settings.changeemailconfirmation': 'Are you sure that you want to change your email to {email}?',
  'settings.changephoto': 'Change photo',
  'settings.changeyouremail': 'Change your email',
  'settings.changeyourlanguage': 'Change your language',
  'settings.changeyourpassword': 'Change your password',
  'settings.confirm': 'Confirm',
  'settings.confirmaccount': 'Confirm your account',
  'settings.deleteaccount': 'Delete my account',
  'settings.deleteaccountinfotext': 'Delete my account and all related information',
  'settings.deleteaccounttext':
    "If you delete your account, you'll no longer be able to sign in. The information will be deleted from your account, but information you have registered, for example in forms, will remain in the system for administrative purposes. It's also possible that some information, such as survey responses, will be stored anonymously for statistical purposes.",
  'settings.disable': 'Disable',
  'settings.enable': 'Enable',
  'settings.exportuserdatainfo': 'Request your personal information.',
  'settings.exportuserdatalink': 'Export personal information',
  'settings.heading': 'Change Your Account Settings',
  'settings.inviteuser': 'Complete your invitation',
  'settings.passwordstrength': 'Password strength',
  'settings.removemydata': 'Remove my data',
  'settings.save': 'Save',
  'settings.twofactorauthentication': 'Two Factor Authentication',
  'settings.twofactorauthenticationdisabled': 'Two Factor Authentication has been disabled',
  'settings.twofactorauthenticationenabled': 'Two Factor Authentication has been enabled',
  'settings.twofactorauthenticationerrorinput': 'Enter a 6-digit code',
  'settings.twofactordisabletext': 'Enter the 6-digit code to disable two factor authentication for your account',
  'settings.twofactorloginhelptext': 'Get a verification code from the Google Authenticator app',
  'settings.twofactorlogintext': 'Enter a verification code',
  'settings.twofactorplaceholder': 'Enter the 6-digit code',
  'settings.twofactorsetuphelptext':
    'Your organization cares about security and chose to enable two-factor authentication to ensure you can sign in to your account securely. You can use a mobile application such as Google or Microsoft Authenticator to scan the QR code. Alternatively, you can enter the security key. The application will then create one-time PIN codes that you must enter additionally during the sign in procedure.',
  'settings.twofactorsetuptitle': 'Setup Two Factor Authentication',
  'settings.uploadphoto': 'Upload photo',
  'settings.uploadtooltip': 'Max File Size: 20mb / Min Height: 200px',
  'tasks.done': 'Done',
  'tasks.hide': 'Hide Tasks',
  'tasks.show': 'Show Tasks',
  'tasks.todo': 'To Do',
  'tasks.youaredone': 'No new tasks now.',
  'task.link.createspecifictasktitle': 'Create',
  'task.createtask.attachedfiles.optional': 'Optional',
  'task.createtask.validation.onboardeeerror': "Task onboardee's missing",
  'task.createtask.validation.keycontactnotassigned':
    'Not assigned to the onboardee. You can add a key contact in the onboardee profile.',
  'task.action.customtasks': 'Show only custom tasks',
  'testimonialinvitation.title': 'Share your experience',
  'tour.filters': 'Click this icon {icon} to change the filters',
  'tour.startdate': 'Click here to change the start date',
  'validation.belgianid': 'Invalid Belgian ID',
  'validation.belgianzip': 'Invalid Belgian Zip',
  'validation.bic': 'Invalid BIC',
  'validation.country': 'Invalid Country',
  'validation.dutchid': 'Invalid Dutch ID',
  'validation.iban': 'Invalid IBAN',
  'validation.password': 'Invalid Password',
  'validation.selectcountryvalidator': 'Please select a country from the list',
  'task.title.activetasks': 'Active tasks',
  'task.title.completedtasks': 'Completed tasks',
  'task.reminder.quiz': 'We have not received your {title} quiz yet. Please complete this quiz as soon as possible.',
  'task.reminder.task': 'We have not received your {title} task yet. Please complete this task as soon as possible.',
  'task.reminder.action.quiz': 'Go to quiz',
  'task.reminder.action.task': 'Go to task',
  'action.canceltask': 'Cancel Task',
  'task.title.assignedtasksoverview': 'Assigned Tasks',
  'task.table.emptytable': 'No Tasks Found',
  'task.comment.newcommentplaceholder': 'Enter your comment...',
  'task.comment.addcomment': 'Add comment',
  'task.attachedfiles.title': 'Attached Files',
  'task.attachedfiles.uploadbutton': 'Attach Files',
  'task.table.attachedfilename': 'Filename',
  'task.table.uploaddate': 'Upload Date',
  'task.action.deleteattachedfile': 'Delete File',
  'task.attachedfiles.emptytable': 'No files attached',
  'task.action.downloadfile': 'Download File',
  'task.upload.newfile': 'New File',
  'task.attachedfiles.uploadingfiles': 'Uploading Files',
  'task.show.more': 'Show more',
  'task.show.less': 'Show less',
  'month.january': 'January',
  'month.february': 'February',
  'month.march': 'March',
  'month.april': 'April',
  'month.may': 'May',
  'month.june': 'June',
  'month.july': 'July',
  'month.august': 'August',
  'month.september': 'September',
  'month.octobre': 'October',
  'month.november': 'November',
  'month.december': 'December',
  'webhook.notification.deleteconfirmation': 'Are you sure you want to delete this webhook?',
  'webhook.notification.deleted': 'Webhook deleted!',
  'webhook.notification.updated': 'Webhook updated!',
  'chatbot.overview.question': 'Question',
  'chatbot.questions.answers': 'Answers',
  'chatbot.questions.answer': 'Answer',
  'chatbot.questions.translation': 'Translation',
  'chatbot.overview.filters': 'Filters',
  'chatbot.questions.addanswer': 'Add Answer',
  'chatbot.translate.language': 'Language',
  'chatbot.translate.translations': 'Chatbot Translate',
  'chatbot.translate.translateinto': 'Translate into',
  'chatbot.notification.success': 'Update Success',
  'chatbot.notification.error': 'Update Failed',
  'chatbot.overview.active': 'Active',
  'chatbot.text.filternotpossible': 'Save the answer before applying filters',
  'link.open': 'Open {lpTitle}',
  'login.invalid_user_or_password': 'Sorry, username or password not found',
  'login.invalid_company': 'Sorry, invalid company',
  'login.user_password_expired': "Password's expired",
  'login.user_locked': 'You’ve reached the maximum login attempts. Your account has been locked for 1 minute.',
  'login.user_locked_due_password_request':
    'Your password has been reset by an administrator. Follow the instructions you have received by email to update your password and continue.',
  'login.user_not_activated':
    'Your account is not activated. If you have lost your activation link, please use the forgot password option.',
  'login.invalid_api_key': 'Sorry, invalid API key',
  'login.api_key_expired': "API key's expired",
  'login.invalid_template': 'Sorry, invalid template',
  'login.invalid_role': 'Sorry, invalid role',
  'login.template_id_required': "template ID's missing",
  'login.invalid_token': 'Sorry, invalid key',
  'login.invalid_admin_user': 'Sorry, invalid admin user',
  'login.company_id_or_subdomain_required': "Company ID or subdomain's missing",
  'login.mfa_required': "multifactor authentication's missing",
  'login.invalid_mfa_provider': 'Sorry, invalid multifactor authentication provider',
  'login.invalid_mfa_code': 'Sorry, invalid multifactor authentication code.',
  'login.unsupported_mfa_setup': 'Sorry, unsupported multifactor authentication setup',
  'notifications.view.notificationtemplates': 'Notification templates',
  'notifications.view.createtemplate': 'Create custom template',
  'employee.view.header': 'My employees',
  'task.createtask.validation.on': 'on',
  'task.createtask.validation.assigneeerrorselectfromlist': 'Please select a user from the list',
  'task.createtask.deadline.title.onboardee': 'Deadline from first workday',
  'task.createtask.deadline.title.offboardee': 'Deadline from last workday',
  'task.createtask.deadline.on.firstworkday': 'the first workday',
  'task.createtask.deadline.on.lastworkday': 'the last workday',
  'task.createtask.deadline.days': 'number of days',
  'task.nocomments': 'No comments here yet',
  'task.noattachements': 'No attachments here yet',
  'task.action.markasdone': 'Mark as done',
  'task.action.taskcompleted': 'Task was marked as done.',
  'task.action.taskcompleteerror':
    "Sorry, we couldn’t mark the task as done. Try again or contact support if that doesn't work.",
  'task.action.taskcancelled': 'Task was cancelled',
  'task.action.taskcancellederror':
    "Sorry, we couldn’t cancel the task. Try again or contact support if that doesn't work.",
  'task.action.taskreopened': 'Task was reopened',
  'task.action.taskreopenederror':
    "Sorry, we couldn’t reopen the task. Try again or contact support if that doesn't work.",
  'task.button.yescancel': 'Yes, cancel task',
  'task.button.yesreopen': 'Yes, reopen task',
  'task.confirm.canceltasktitlewithname': 'Are you sure you want to cancel {task}?',
  'task.confirm.reopentasktitlewithname': 'Are you sure you want to reopen {task}?',
  'task.deadline.thelastworkday': 'the last workday',
  'task.deadline.thefirstworkday': 'the first workday',
  'task.keycontactoptiondisabled.onboardee':
    'Some key contacts need to be assigned from the onboardee profile before you can select them here.',
  'task.keycontactoptiondisabled.offboardee':
    'Some key contacts need to be assigned from the offboardee profile before you can select them here.',
  'task.notaskscreated': 'No tasks here yet',
  'task.notaskscreateddescription': 'Go ahead and create one.',
  'task.table.allassignee': 'All assignees',
  'task.table.others': 'Others',
  'task.reminder.action.tasks': 'Go to tasks',
  'task.taskdeleted': 'Task was deleted',
  'general.general': 'General',
  'general.who': 'Who',
  'general.nomatchingresults': 'No matching results',
  'general.nomatchingresultsdescription': 'Check keywords or filters and try again.',
  'ui.comments': 'Comments',
  'ui.attachements': 'Attachments',
  'ui.nomatchingkeywordsfilters': 'Check keywords or filters and try again.',
  'ui.total': 'Total',
  'keycontactgroups.overview.title': 'Key contact groups',
  'keycontactgroups.overview.newgroup': 'New group',
  'keycontactgroups.overview.rename': 'Rename group',
  'keycontactgroups.overview.edittranslations': 'Edit translations',
  'keycontactgroups.overview.delete': 'Delete group',
  'keycontactgroups.overview.editpermissions': 'Edit permissions',
  'keycontactgroups.overview.deletewarning':
    "Are you sure you want to delete {group}? Deleting a group affects any related tasks and onboardee's key contacts, and it can't be undone.",
  'keycontactgroups.overview.create': 'Create',
  'keycontactgroups.overview.ui.rename': 'Rename',
  'keycontactgroups.overview.showlist': 'Show list of employees to this group',
  'keycontactgroups.overview.empty.title': 'No groups here yet',
  'keycontactgroups.overview.empty.description': 'Go ahead and create one.',
  'keycontactgroups.overview.permissions.userdetails': 'User details',
  'keycontactgroups.overview.permissions.worksection': 'Work section',
  'keycontactgroups.overview.permissions.otherdetails': 'Other details',
  'keycontactgroups.overview.permissions.introduction': 'Introduction / Goodbye',
  'keycontactgroups.overview.permissions.view': 'View',
  'keycontactgroups.overview.permissions.viewdownload': 'View (and download)',
  'keycontactgroups.overview.alert.success': 'Settings were updated',
  'keycontactgroups.overview.alert.error':
    "Sorry, we couldn't update the settings. Try again or contact support if that doesn't work.",
  'keycontactgroups.overview.alert.successdelete': 'Group was deleted',
  'keycontactgroups.overview.alert.errordelete':
    "Sorry, we couldn't delete the group. Try again or contact support if that doesn't work.",
  'keycontactgroups.overview.alert.successrename': 'Group was renamed',
  'keycontactgroups.overview.alert.errorrename':
    "Sorry, we couldn't rename the group. Try again or contact support if that doesn't work.",
  'keycontactgroups.overview.alert.successcreate': 'Group was created',
  'keycontactgroups.overview.alert.errorcreate':
    "Sorry, we couldn't create the group. Try again or contact support if that doesn't work.",
  'keycontactgroups.overview.alert.successpermissions': 'Permissions were updated',
  'keycontactgroups.overview.alert.errorpermissions':
    "Sorry, we couldn't update the permissions. Try again or contact support if that doesn't work.",
  'keycontactgroups.overview.alert.successtranslate': 'Translations were saved',
  'keycontactgroups.overview.alert.errortranslate':
    "Sorry, we couldn't save the translations. Try again or contact support if that doesn't work.",
  'keycontactgroups.overview.confirmdelete': 'Yes, delete group',
  'consent.overlay.title': 'Want to see this content?',
  'consent.overlay.description': 'To load this content, you need to consent to cookies from {contentType}',
  'consent.overlay.confirm': 'I Consent',
  'consent.overlay.policy': 'Read Privacy Policy',
  'keycontact.warnusercannotedit': 'Contact your administrator to edit this key contact group',
  'settings.title.languages': 'Languages',
  'settings.languages.default': 'Default language',
  'settings.languages.active': 'Active languages',
  'modules.overview.sortsuccess': 'Modules were updated',
  'modules.overview.sorterror':
    "Sorry, we couldn't update the modules. Try again or contact support if that doesn't work.",
  'modules.title.companypages': 'Company pages',
  'modules.title.didyouknow': 'Did You Know',
  'modules.title.introduceyourself': 'Introduce Yourself',
  'modules.title.pulsecheck': 'Pulse Check',
  'modules.title.testyourself': 'Test Yourself',
  'modules.title.videomodule': 'Video Message',
  'modules.title.yourcontacts': 'Your Contacts',
  'modules.title.testimonials': 'Testimonials',
  'modules.title.create.companypages': "'Company Pages' module",
  'modules.title.create.didyouknow': "'Did You Know' module",
  'modules.title.create.introduceyourself': "'Introduce Yourself' module",
  'modules.title.create.pulsecheck': "'Pulse Check' module",
  'modules.title.create.testyourself': "'Test Yourself' module",
  'modules.title.create.videomodule': "'Video Message' module",
  'modules.title.create.yourcontacts': "'Your Contacts' module",
  'modules.title.create.testimonials': "'Testimonial' module",
  'modules.title.create.form': "'Form' module",
  'modules.title.create.learningpages': "'Learning Pages' module",
  'modules.title.create.goodbye': "'Goodbye message' module",
  'modules.title.goodbye': 'Goodbye message',
  'modules.showonlymoduleswithmissingfilters': 'Show only modules with missing filters',
  'form.newreminder': 'It seems there are tasks you haven’t gotten around to yet. Want to check them out now?',
  'datepicker.selectadate': 'Select date',
  'onboardeeuser.personalemail': 'Personal email'
};
